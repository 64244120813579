import React from "react"
import { graphql } from "gatsby"
import "../components/queries/fragments-municipality"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Charts from "../components/layout/content/charts"

const citiesInfoPage = ( { data: { allItems: { nodes }}} ) => {
  /**
   * 
   * This page is only for generating content what I'll insert into Drupal static pages. It is not linked through web.
   * 
   */
  
  const sortingFields = [
    'field_population_total',
    'field_size_total',
    'field_population_density_total',
    'field_population_avg_age',
    'field_population_index_old_age'
  ]

  const fullChartPath = '/obce/zoznam-obci-'
  const linkName = "Zoznam obcí"

  return (
  <Layout>
      <Seo title="Uvodna stranka" />
      <br /><br /><br />
      <h2>Rebríčky obcí</h2>
      <p>Celkový počet obcí: {nodes.length}</p>
      <br />
      {
        sortingFields.map(item => 
          <Charts arr={nodes} field={item} chartPath={fullChartPath} linkName={linkName} />
      )}
    </Layout>    
  )
}

export default citiesInfoPage

export const query = graphql`
  query {
    allItems: allNodeMunicipality {
            nodes {
                    ...infoPageChartQueries
                }
        }
    }
`
